@tailwind base;
@tailwind components;
@tailwind utilities;

.section {
  @apply py-8 lg:py-10  flex items-center;
}

.text-new-green {
  color: #62944e;
}
.text-new-blue {
  color: #048dd3;
}

.bg-new-blue {
  background-color: #e4efe0d2;
}

.bg-banner {
  background-image: url("../src/assets/bg-banner.jpg");
  background-color: #e4efe0; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.dropdown:hover .dropdown-menu {
  display: block;
}
